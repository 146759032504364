<template>
  <div>
    <work-view-detail :result="result" :is-show-loading="isShowLoading"/>
    <work-view-date-of-work :result="result" :is-show-loading="isShowLoading"/>
    <work-view-jsa :work-id="id" :card-collapsed="true"/>
    <work-view-safety-check :work-id="id"/>

    <div class="text-center mb-3">
      <b-button variant="outline-secondary" class="mr-1"
                :to="{name: 'pageWorkOperation', param: {id: id}}"
      >
        <feather-icon icon="ChevronLeftIcon"/>
        {{ $t('general.btnBack') }}
      </b-button>
      <b-button variant="success" @click="confirmCloseJob" :disabled="isBtnDisabled">
        <b-spinner small v-if="isBtnDisabled"/>
        <feather-icon icon="CheckIcon" v-else/>
        {{ $t('work.btnConfirmClose') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BSpinner} from 'bootstrap-vue'
import formMixins from "@/mixins/formMixins"
import WorkViewDetail from "@/components/work/WorkViewDetail"
import WorkViewSafetyCheck from "@/components/work/WorkViewSafetyCheck"
import WorkViewDateOfWork from "@/components/work/WorkViewDateOfWork"
import WorkViewJsa from "@/components/work/WorkViewJsa"
import {WorkService} from "@/services"
import masterMixins from "@/mixins/masterMixins"

const workService = new WorkService()

export default {
  name: 'WorkFromFinish',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BSpinner,
    WorkViewDetail,
    WorkViewDateOfWork,
    WorkViewJsa,
    WorkViewSafetyCheck,
  },
  mixins: [masterMixins, formMixins],
  async created() {
    this.id = this.$route.params.id
    await this.getData()
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    result: {},
  }),
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.result = result

        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageWork'})
      }
    },
    confirmCloseJob() {
      if(this.result.workStep === 20) {
        this.workOwnerCloseJob()
      } else if (this.result.workStep === 21) {
        this.safetyCloseJob()
      }
    },
    workOwnerCloseJob() {
      this.isBtnDisabled = true
      workService.closeJob(this.id)
          .then(({isSuccess, data}) => {
            if (isSuccess) {
              this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
              this.$router.replace({name: 'pageWork'})
            } else {
              this.isBtnDisabled = false
              this.$store.commit('main/setToastError', data)
            }
          })
    },
    safetyCloseJob() {
      this.isBtnDisabled = true
      workService.safetyCloseJob(this.id)
          .then(({isSuccess, data}) => {
            if (isSuccess) {
              this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
              this.$router.replace({name: 'pageWork'})
            } else {
              this.isBtnDisabled = false
              this.$store.commit('main/setToastError', data)
            }
          })
    }
  }
}
</script>

<style lang="scss">
</style>
